<template>
  <el-dialog title="分享用户" width="80%" :close-on-click-modal="false" append-to-body :visible.sync="visible">
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="b_t b_r b_l b_bot">
          <div class="flex_l_c b_b p_all1">
            <el-input style="width: 60%" class="m_r1" size="small" v-model="searchStr" placeholder="请输入关键字进行搜索"
              clearable></el-input>
            <el-button type="primary" @click="refreshList()" size="small" icon="el-icon-search">搜索</el-button>
          </div>
          <div class="flex_b_c b_b" v-for="(item, index) in userList" :key="item.id">
            <div class="p_all1">
              <span class="el-icon-user-solid m_r1"></span>
              <span>{{ item.name }}</span>
            </div>
            <div class="p_all1 b_l">
              <el-button type="primary" @click="toLeft(item, index, 0)" size="mini" icon="el-icon-right"></el-button>
            </div>
          </div>
        </div>
        <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageNo"
          :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="total" background
          layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
      </el-col>
      <el-col :span="12">
        <div class="b_t b_r b_l">
          <div class="b_b" style="padding: 15px">分享列表</div>
          <div class="flex_b_c b_b p_all1" v-for="(item, index) in authorityList" :key="item.id">
            <div>
              <span class="el-icon-user-solid m_r1"></span>
              <span>{{ item.name }}</span>
            </div>
            <el-button icon="el-icon-delete" size="mini" type="danger" @click="toLeft(item, index, 1)">删除</el-button>
          </div>
        </div>
      </el-col>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">取消</el-button>
      <el-button size="small" type="primary" @click="doSubmit()" v-noMoreClick>保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      searchStr: '',
      userList: [],
      authorityList: [],
      files: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      log: {
        operModular: "6",//模块
        operType: "10",//类型
        operTerm: "",//内容
        operSystem: "1",//结果
      },
    }
  },
  methods: {
    init(file) {
      this.authorityList = []
      this.files = file;
      this.refreshList();
      this.visible = true
    },
    refreshList() {
      this.loading = true
      this.$axios(this.api.user.sysuserQueryAllList, {
        'current': this.pageNo,
        'size': this.pageSize,
        orderBy: '',
        name: this.searchStr,
        loginName: '',
      }, 'get').then(data => {
        if (data && data.status) {
          this.userList = data.data.records
          this.total = parseInt(data.data.total)
          this.loading = false
        }
      })
    },
    // 移到右边
    toLeft(item, index, type) {
      if (type == 0) {
        let pd = false
        this.authorityList.forEach(function (v) {
          if (item.id == v.id) {
            pd = true
          }
        })
        if (pd) {
          this.$message.error("已经在分享列表")
          return false
        }
        // this.userList.splice(index, 1)
        this.authorityList.push(item)
      } else {
        this.authorityList.splice(index, 1)
        // this.userList.push(item)
      }

    },
    // 保存
    doSubmit() {
      // console.log(this.files);
      // console.log(this.authorityList);

      let files = this.files.map(item => {
        return item.id
      }).join(',')
      let shareUserIds = this.authorityList.map(item => {
        return item.id
      }).join(',')

      this.$axios(this.api.zlzs.ShareSave, {
        "fileIds": files,
        "shareUserIds": shareUserIds,
      }, "post").then(data => {
        this.loading = false
        if (data && data.status) {
          this.$message.success("分享成功")
          this.log.operSystem = '0'
          this.visible = false
          this.$emit('refreshDataList')
        } else {
          this.$message.error(data.msg)
        }

        // this.logSave(this.files)
      })
    },
    //保存日志
    logSave(dataListSelections) {
      let that = this;
      let batchList = []
      dataListSelections.forEach(function (v) {
        let log = JSON.parse(JSON.stringify(that.log))
        log.operTerm = v.fileName
        batchList.push(log)
      })
      this.$axios(this.api.zlzs.batchInsertLogin, batchList, 'post').then(data => {

      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageNo = 1
      this.refreshList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val
      this.refreshList()
    },
  }
}
</script>

<style scoped></style>
